.folder-breadcrumbs-container {
  position: absolute;
  top: 0;
  z-index: 1;
  padding-left: 32px;
  padding-right: 32px;
}

.folder-title-action-dropdown .tide-action-dropdown-action:last-child {
  color: var(--color-s3-6);
}

.upload-video-modal .tide-base-modal-content--body {
  height: auto;
}
