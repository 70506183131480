.email-and-domain-list-editor .multi-string-compact-input {
  font-family: var(--main-font-family);
}

.email-and-domain-list-editor .multi-string-compact-input[placeholder] {
  width: 100%;
}

.email-and-domain-list-editor .multi-string-compact-input[placeholder=''] {
  width: auto;
}

.email-and-domain-list-editor.error .multi-string-compact-container {
  border-color: var(--color-s3-6);
}

.email-and-domain-list-editor .multi-string-compact-bottom-bar {
  display: none;
}

input.multi-string-compact-input::placeholder {
  color: var(--color-p1-5);
}
