.link-privacy-selector__radio {
  margin-top: 2px;
  margin-left: 8px;
  margin-right: 8px;
}

.link-privacy-selector__content {
  width: 100%;
}

.link-privacy-selector__title {
  font-family: var(--main-font-family--semibold);
}

.link-privacy-selector__description {
  color: var(--color-p1-6);
  font-size: var(--font-size-subtle);
  line-height: var(--main-spacing-paragraph-2);
}

.link-privacy-selector__edit-group-button {
  margin-top: -10px;
  margin-bottom: -8px;
  margin-right: -6px;
}

.link-privacy-selector__email-list {
  margin-bottom: 8px;
  margin-right: 8px;
}

.link-privacy-selector__email-list-header {
  margin-top: 5px;
  font-size: var(--font-size-subtle);
}

.link-privacy-selector__label--with-description .link-privacy-selector__edit-group {
  display: flex;
  align-self: center;
  height: 100%;
}

.link-privacy-selector__label--with-description .link-privacy-selector__edit-group-button {
  margin-right: 20px;
}

.link-privacy-selector__label--with-description .link-privacy-selector__delete-group {
  display: flex;
  align-self: center;
  height: 100%;
}

.link-privacy-selector__label--with-description .link-privacy-selector__delete-group-button {
  margin-right: 4px;
}

.link-privacy-selector .disable-chat-warning {
  display: inline;
}

.link-privacy-selector .disable-chat-warning__set-chat {
  line-height: 24px;
  text-decoration: underline;
  color: var(--color-p1-10);
}
