.sunset-banner {
  background-color: #feeaea;
  border-radius: 3px 3px 0 0;
  border-color: #f05b4d;
  border-style: solid;
  border-top-width: 3px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  margin: 16px 32px 0;
  padding: 16px;
}
