.cloned-recording-banner {
  height: 40px;
  width: 100%;
  background-color: var(--color-p2-2);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: var(--color-p2-6);
}

.cloned-recording-banner-notice {
  padding-left: 4px;
}

.cloned-recording-banner-close {
  position: absolute;
  right: 12px;
}
