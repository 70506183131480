.selection-block {
  display: flex;
  border: 1px solid var(--color-p3-4);
  border-radius: 3px;
  margin-top: 8px;
  padding: 9px;
}

.selection-block:first-child {
  margin-top: 0;
}

.selection-block:hover {
  background-color: var(--color-p3-2);
  border: 1px solid var(--color-p3-5);
}

.selection-block.selection-block--selected {
  background-color: var(--color-p3-2);
  border: 1px solid var(--color-p3-6);
}
